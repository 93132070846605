import React, { useCallback, useState } from "react";
import {
  FaFacebook,
  FaLocationArrow,
  FaShareAlt,
  FaShoppingCart,
  FaTwitter,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, setCartLength } from "../../../slice/CartSlice";
import { Link, useNavigate } from "react-router-dom";
import { IsLoggedIn } from "../../../slice/UserSlice";
import Loader from "../../../common/loader/Loader";
import { BsCart3 } from "react-icons/bs";
// import {
//   decrementQuantity,
//   incrementQuantity,
// } from "../../../slice/ProductSlice";
import { RiWhatsappFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { PostRequest } from "../../../api/Request";
import { showErrorToast, showSuccessToast } from "../../../utils/toast";

interface ProductInfoProps {
  product: any;
  setProduct: any;
  variations: any;
  productsWithSameVariant: any;
  handleSelectVariant: any;
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  product,
  setProduct,
  variations,
  productsWithSameVariant,
  handleSelectVariant,
}) => {
  
  const productUrl = window.location.href;
  const [quantity, setQuantity] = useState<number>(1);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isInCart, setIsInCart] = useState(false); // State to track if product is added to cart


  let userIsLoggedIn = useSelector(IsLoggedIn);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(e.target.value));
  };
  // Updated handleAddToCart function
  // const handleAddToCart = async (product: any, index: number) => {
  //   setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));

  //   try {
  //     let response = await PostRequest("/addItemToCart", {
  //       productId: product?._id,
  //       quantity: product?.quantityByUser,
  //     });

  //     if (response.status === 200) {
  //       dispatch(
  //         setProduct({
  //           product: { ...product, addToCartStatus: "Update Cart" },
  //           index,
  //         })
  //       );
  //       dispatch(setCartLength(response.data.count));

  //       if (product.quantityByUser === 1 && response.data.item.quantity === 1) {
  //         showSuccessToast("Product added to cart");
  //       } else {
  //         showSuccessToast("Updated Successfully");
  //       }
  //     }
  //   } catch (error: any) {
  //     if (
  //       error?.response?.data?.message === "Invalid User" ||
  //       error?.response?.data?.message === "Invalid token"
  //     ) {
  //       showInfoToast("Please Login");
  //       navigate("/login");
  //     } else {
  //       showErrorToast(error?.response?.data?.message);
  //     }
  //   } finally {
  //     // Reset loading state for this specific product
  //     setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
  //   }
  // };

  // const handleIncrementForQuantity = async (product: any, index: number) => {
  //   let updateAllProduct = [...allProduct];

  //   // Set loading state for this specific product
  //   setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));

  //   try {
  //     // Make the API request to update item quantity
  //     const response = await PostRequest("/updateItemQuantity", {
  //       productId: product?._id,
  //       quantity: product?.quantityByUser + 1,
  //     });

  //     // Check if the response is successful
  //     if (response.status === 200) {
  //       // Update quantity logic
  //       const newQuantity = Math.min(
  //         updateAllProduct[index].quantityByUser + 1,
  //         updateAllProduct[index].totalStock
  //       );

  //       // Dispatch the action to update the product in Redux store
  //       dispatch(
  //         setProduct({
  //           product: {
  //             ...product,
  //             quantityByUser: newQuantity,
  //           },
  //           index,
  //         })
  //       );

  //       // Show success toast notification
  //       showSuccessToast("Updated Successfully");
  //     }
  //   } catch (error: any) {
  //     // Handle error appropriately
  //     showErrorToast(error?.response?.data?.message);
  //   } finally {
  //     // Reset loading state for this specific product
  //     setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
  //   }
  // };

  // const handleDecrementForQuantity = async (product: any, index: number) => {
  //   setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));

  //   try {
  //     if (product.quantityByUser === 1) {
  //       // Remove item from cart when quantity is 1
  //       const response = await PostRequest("/removeItemFromCart", {
  //         productId: product?._id,
  //       });

  //       if (response.status === 200) {
  //         // Update state to reflect removal

  //         dispatch(
  //           setProduct({
  //             product: {
  //               ...product,
  //               quantityByUser: 1,
  //               addToCartStatus: "Add to cart",
  //             },
  //             index,
  //           })
  //         );
  //       }
  //     } else {
  //       // Decrement quantity when it's greater than 1
  //       const response = await PostRequest("/updateItemQuantity", {
  //         productId: product?._id,
  //         quantity: product.quantityByUser - 1,
  //       });

  //       if (response.status === 200) {
  //         dispatch(
  //           setProduct({
  //             product: {
  //               ...product,
  //               quantityByUser: product.quantityByUser - 1,
  //             },
  //             index,
  //           })
  //         );
  //         showSuccessToast("Updated Successfully");
  //       }
  //     }
  //   } catch (error: any) {
  //     showErrorToast(error?.response?.data?.message);
  //   } finally {
  //     setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
  //   }
  // };
  
  const handleDecrementForQuantity = async () => {
    let newProduct = { ...product };
    
    if (newProduct.quantityByUser > 1) {
      newProduct.quantityByUser -= 1;
  
      // Update the product state
      setProduct((prevProduct: any) => {
        return { ...prevProduct, product: newProduct };
      });
  
      const token = localStorage.getItem("userToken"); // Adjust based on where your token is stored
      try {
        if (!token) {
          // No token, save updated quantity to localStorage
          const localCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const existingProductIndex = localCart.findIndex((item: any) => item._id === newProduct._id);
  
          if (existingProductIndex > -1) {
            // Update the quantity for the existing product
            localCart[existingProductIndex].quantityByUser = newProduct.quantityByUser;
            localStorage.setItem("cart", JSON.stringify(localCart));
            showSuccessToast("Quantity updated");
          } else {
            showErrorToast("Product not found in cart");
          }
          return; // Exit early since we handled it locally
        }
  
        // Token exists, make the API call
        const response = await PostRequest("/updateItemQuantity", {
          productId: newProduct?._id, // Use newProduct to ensure correct product ID
          quantity: newProduct.quantityByUser, // Send updated quantity
        });
      
        if (response.status === 200) {
          // showSuccessToast("Updated quantity");
        } else {
          showErrorToast(response.data?.error?.message || "Error updating quantity");
        }
      } catch (error: any) {
        console.error("Error occurred while updating quantity:", error);
        // Show error message from the error response or a generic error message
        showErrorToast(error?.response?.data?.message || "An unexpected error occurred");
      }
    }
  };
  
  const handleIncrementForQuantity = async () => {
    let newProduct = { ...product };
    
    // Increment quantity if it's less than total stock
    if (newProduct.quantityByUser < newProduct.totalStock) {
      newProduct.quantityByUser += 1;
  
      // Update the product state
      setProduct((prevProduct: any) => {
        return { ...prevProduct, product: newProduct };
      });
  
      const token = localStorage.getItem("userToken"); // Adjust based on where your token is stored
      try {
        if (!token) {
          // No token, save updated quantity to localStorage
          const localCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const existingProductIndex = localCart.findIndex((item: any) => item._id === newProduct._id);
  
          if (existingProductIndex > -1) {
            // Update the quantity for the existing product
            localCart[existingProductIndex].quantityByUser = newProduct.quantityByUser;
          } else {
            // Add new product to the local cart
            localCart.push({
              ...newProduct,
              quantityByUser: newProduct.quantityByUser,
            });
          }
  
          localStorage.setItem("cart", JSON.stringify(localCart));
          showSuccessToast("Quantity updated in local cart");
          return; // Exit early since we handled it locally
        }
  
        // Token exists, make the API call
        const response = await PostRequest("/updateItemQuantity", {
          productId: newProduct?._id, // Use newProduct to ensure correct product ID
          quantity: newProduct.quantityByUser, // Send updated quantity
        });
  
        if (response.status === 200) {
          // showSuccessToast("Updated quantity");
        } else {
          showErrorToast(response.data?.error?.message || "Error updating quantity");
        }
      } catch (error: any) {
        console.error("Error occurred while updating quantity:", error);
        // Show error message from the error response or a generic error message
        showErrorToast(error?.response?.data?.message || "An unexpected error occurred");
      }
    }
  };
  

  const calculateDiscountPercentage = (salePrice: any, regularPrice: any) => {
    if (regularPrice <= salePrice) {
      throw new Error("Regular price must be greater than sale price.");
    }
    const discountPercentage =
      ((regularPrice - salePrice) / regularPrice) * 100;
    return discountPercentage.toFixed(2); // Returns percentage with two decimal places
  };

 
  
  
  const handleAddToCartClick = (product: any) => {
    handleAddToCart(product);
    setIsInCart(true); // Set the product as added to cart
  };

  const handleAddToCart = useCallback(async (product: any) => {
    const token = localStorage.getItem("userToken"); // Adjust based on where your token is stored
  
    setIsAddedToCart(true); // Show loader by setting loading state to true
    try {
      if (!token) {
        // If no token, save to localStorage
        const localCart = JSON.parse(localStorage.getItem("cart") || "[]");
        const existingProductIndex = localCart.findIndex((item: any) => item._id === product._id);
  
        if (existingProductIndex > -1) {
          // Update quantity if product already exists in the cart
          localCart[existingProductIndex].quantityByUser += product.quantityByUser;
        } else {
          // Add new product to the cart
          localCart.push({
            ...product,
            quantityByUser: product.quantityByUser,
          });
        }
  
        // Save updated cart back to localStorage
        localStorage.setItem("cart", JSON.stringify(localCart));
  
        // Dispatch action to update cart length
        dispatch(setCartLength(localCart.length));
        showSuccessToast("Product added to cart locally");
  
        // Update the product's addToCartStatus in the UI
        setProduct((prevProduct: any) => {
          return {
            ...prevProduct,
            product: {
              ...prevProduct.product,
              addToCartStatus: "Update Cart",
            },
          };
        });
  
      } else {
        // If token exists, proceed with the API call
        let response = await PostRequest("/addItemToCart", {
          productId: product?._id,
          quantity: product?.quantityByUser,
        });
  
        if (response.status === 200) {
          // Add product to the Redux store
          dispatch(
            addProduct({
              ...product,
              total: Number(product.salePrice) * product.quantityByUser,
            })
          );
  
          // Update the product's addToCartStatus in the UI
          setProduct((prevProduct: any) => {
            return {
              ...prevProduct,
              product: {
                ...prevProduct.product,
                addToCartStatus: "Update Cart",
              },
            };
          });
  
          // Dispatch action to update cart length
          dispatch(setCartLength(response.data.count));
        }
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    } finally {
      setIsAddedToCart(false); // Hide loader by setting loading state to false
    }
  }, [dispatch]);
  
  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=Check out this product: ${encodeURIComponent(
      productUrl
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  function shareOnFacebook() {
    const facebookUrl = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
      productUrl
    )}`;
    window.open(facebookUrl, "_blank");
  }

  function shareOnTwitter() {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      productUrl
    )}&text=Check out this product!`;
    window.open(twitterUrl, "_blank");
  }

  return (
    <div className="p-6 sm:p-3 bg-white shadow-lg rounded-lg">
      {isAddedToCart && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <Loader /> {/* Your loader component */}
        </div>
      )}
      {/* <h2 className="text-2xl text-center font-bold text-gray-800 capitalize mb-4 bg-[#0047AB] text-white p-2">Air Conditioner</h2> */}
      <div className="flex items-center justify-between">
        {/* <span className="bg-red-500 text-white px-1 rounded h-4 text-xs">
          27% off
        </span> */}
        <Link
          to="/contact"
          className="block text-blue-500 mb-2 text-center flex items-center hover:underline text-xs"
        >
          <FaLocationArrow className="mr-2" />
          Visit Cayro Shop store
        </Link>
      </div>
      <h4 className="text-xl  mb-2 sm:text-sm">{product?.productName} </h4>
      <div className="mb-4">
        {/* <div className="text-gray-500 mb-2 sm:text-xs"> */}
        {/* in{" "} */}
        {/* <p className="text-blue-500 inline">
            {product?.categoryId?.category}
          </p> */}
        {/* </div> */}
        {/* <div className="flex items-center mb-2">
          <div className="flex items-center">
            {[...Array(5)].map((_, i) => (
              <svg
                key={i}
                className="w-6 h-6 sm:h-3 sm:w-3 text-yellow-500"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2l3.09 6.26L22 9.27l-5 4.87L18.18 22 12 18.27 5.82 22 7 14.14 2 9.27l6.91-1.01L12 2z" />
              </svg>
            ))}
          </div>
          <Link to="#reviews" className="text-blue-500 ml-2 sm:text-sm">
            5.00 (25 Reviews)
          </Link>
        </div> */}
      </div>
      <div className="mb-4 flex items-center space-x-4">
        <p className="text-2xl flex justify-center items-center font-semibold sm:text-sm">
          ₹{product?.salePrice}{" "}
        </p>
        <p className="text-md font-bold sm:text-sm">M.R.P</p>
        <p className="text-md line-through text-red-500 sm:text-sm">
          ₹{product?.regularPrice}
        </p>
        <span className={"sm:text-[13px] bg-white ml-[0.5rem] text-[#CD0B39]"}>
          {calculateDiscountPercentage(
            Number(product?.salePrice),
            Number(product?.regularPrice)
          ) + "% off"}
        </span>
        <p
          className={
            product.totalStock === 0
              ? "text-blue-800 font-semibold text-sm sm:text-xs"
              : "text-green-500 font-semibold text-sm sm:text-xs"
          }
        >
          {product.totalStock === 0 ? "Out of stock" : "Available in stock"}
        </p>
      </div>
      {product.totalStock !== 0 && (
  <div className="flex flex-col items-start">
    {isInCart && product?.quantityByUser > 0 && ( // Show section only if quantity is greater than 0
      <div className="flex items-center space-x-2 mb-4">
        <button
          type="button"
          onClick={handleDecrementForQuantity}
          className="p-2 px-6 text-[17px] flex justify-center items-center sm:px-2 bg-gray-200 rounded sm:h-[28px] text-bold"
        >
          -
        </button>
        <input
          type="number"
          className="w-16 sm:w-8 p-2 text-[17px] border sm:h-[28px] rounded text-center"
          value={product?.quantityByUser}
          onChange={handleQuantityChange}
          readOnly // Optionally make it read-only to prevent manual input
        />
        <button
          type="button"
          onClick={handleIncrementForQuantity}
          className="p-2 sm:p-3 flex justify-center items-center px-6 bg-gray-200 sm:h-[28px] rounded text-bold"
        >
          +
        </button>
      </div>
    )}
    {isInCart && product?.quantityByUser === product?.totalStock && (
      <p
        className="text-xs text-center text-purple-600 mb-[0.6rem]"
        id="hs-validation-name-error-helper"
      >
        Only {product?.totalStock} left in stock
      </p>
    )}
    <div className="flex space-x-4">
      <button
        onClick={() => handleAddToCartClick(product)}
        className="border sm:min-w-[120px] sm:text-xs border-blue-500 text-blue-500 sm:px-2 sm:py-1 px-4 py-2 rounded flex-1 min-w-[184px] hover:bg-white hover:text-blue-500"
      >
        Add to cart
        <BsCart3 className="inline mb-[0.2rem] ml-[0.2rem]" />
        {product?.isLoading && (
          <div className="absolute top-[-18px] left-[14px]">
            <Loader />
          </div>
        )}
      </button>

      <button
        type="submit"
        onClick={() => {
          handleAddToCartClick(product);
          navigate("/cart");
        }}
        className="border sm:min-w-[120px] rounded-lg transition-transform duration-300 transform sm:text-xs text-white bg-blue-500 px-4 py-2 rounded flex-1 min-w-[184px] hover:scale-105"
      >
        Buy Now
      </button>
    </div>
  </div>
)}

      
      <div>
        {variations.length > 0 &&
          variations.map((variation: any) => {
            return (
              <div>
                <p className="text-[#555] font-[400] mt-[0.5rem]">
                  {variation?.name} :
                </p>
                <ul className="flex items-center">
                  {variation?.value?.length > 0 &&
                    variation?.value?.map((value: string) => {
                      return (
                        <li
                          onClick={() => handleSelectVariant(variation, value)}
                          className="mx-[1rem] my-[0.4rem]"
                        >
                          <p
                            className={
                              product?.variation?.filter(
                                (varItem: any) =>
                                  varItem.name === variation.name
                              ).length > 0 &&
                              product?.variation?.find(
                                (varItem: any) =>
                                  varItem.name === variation.name
                              )?.value === value
                                ? "bg-[#3B81F6] text-white cursor-pointer rounded-xl p-[0.4rem] text-[14px]"
                                : "border-[1.5px] border-solid border-[#ddd] text-black cursor-pointer rounded-xl p-[0.4rem] text-[14px]"
                            }
                          >
                            {value}
                          </p>
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          })}
      </div>
      <div className="flex space-x-4 mt-4">
        {/* <p className="ml-4 gap-3 text-blue-500 sm:text-xs flex items-center flex-1 min-w-[184px] sm:min-w-[100px] hover:text-blue-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-arrow-down-up"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"
            />
          </svg>
          Compare
        </p> */}
        {/* <p className="text-blue-500 gap-3 sm:text-xs flex items-center flex-1 min-w-[184px] sm:min-w-[100px] hover:text-blue-800">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-heart"
            viewBox="0 0 16 16"
          >
            <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15" />
          </svg>
          Add to wishlist
        </p> */}
      </div>
      {product?.salePrice ? (
        <div className="mt-4">
          <ul className="space-y-2">
            <li className="flex items-center sm:text-xs">
              <svg
                className="w-6 h-6 mr-2"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5zm1.294 7.456A2 2 0 0 1 4.732 11h5.536a2 2 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456M12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2" />
              </svg>
              Free shipping and instant cashback on orders over ₹2000
            </li>
            <li className="flex items-center sm:text-xs">
              <svg
                className="w-6 h-6 mr-2"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z" />
              </svg>
              Save extra with No Cost EMI
            </li>
            <li className="flex items-center sm:text-xs">
              <svg
                className="w-7 h-7 mr-2"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M21 13.997h-6.76c-.353-1.12-1.419-2-2.74-2s-2.387.88-2.74 2h-6.76c-.413 0-.5.207-.5.62v4.756c0 .407.293.621.5.621h.5v1.621c0 .413.207.5.62.5h2.76c.413 0 .621-.207.621-.5v-1.621h10.502v1.621c0 .413.207.5.62.5h2.76c.413 0 .621-.207.621-.5v-1.621h.5c.207 0 .5-.207.5-.621v-4.756c0-.413-.207-.62-.5-.62zm-16-6c-.62 0-1-.38-1-1s.38-1 1-1 1 .38 1 1-.38 1-1 1zm8 0c-.62 0-1-.38-1-1s.38-1 1-1 1 .38 1 1-.38 1-1 1zm8 0c-.62 0-1-.38-1-1s.38-1 1-1 1 .38 1 1-.38 1-1 1zM4.5 14.997h2v1.005h-2v-1.005zm0 2.007h2v1.005h-2v-1.005zm14.998 0v1.005h-10.502v-1.005h10.502z" />
              </svg>
              100% money back guarantee
            </li>
            <li className="flex items-center">
              <div className="flex items-center">
                <p className="mr-2  flex justify-center items-center">
                  Share :-
                </p>
                <p
                  onClick={shareOnFacebook}
                  className=" rounded-full w-8 h-8 flex items-center justify-center mr-1"
                >
                  <FaFacebook className="text-[#0665FE] text-[25px]" />
                </p>
                <p
                  onClick={shareOnTwitter}
                  className=" rounded-full w-8 h-8 flex items-center justify-center mr-1"
                >
                  <FaXTwitter className="text-[black] text-[25px]" />
                </p>

                <p
                  onClick={shareOnWhatsApp}
                  className=" rounded-full w-8 h-8 flex items-center justify-center mr-1"
                >
                  <RiWhatsappFill className="text-[#22D367] text-[25px]" />
                </p>
              </div>
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductInfo;
