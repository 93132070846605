import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  asyncLoginWithTruecaller,
  loginUsingOTP,
  loginUsingWhatsApp,
  sendOTP,
} from "../../slice/UserSlice";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";

const LoginWithOtp = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [otpMethod, setOtpMethod] = useState(""); // New state for selected OTP method

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const handleTruecaller = async () => {
  //     const baseUrl = "truecallersdk://truesdk/web_verify";
  //     const requestNonce = uuidv4(); // Generate a unique nonce

  //     const queryParamsObject = {
  //       type: "btmsheet",
  //       requestNonce,
  //       status: "flow_invoked",
  //       partnerKey: "mOPlk66189725603a4e21ab8052535a0e4a1d",
  //       partnerName: "cayroshop",
  //       lang: "english",
  //       privacyUrl: "https://www.cayroshop.com/privacy-policy",
  //       termsUrl: "https://www.cayroshop.com/terms-of-service",
  //       loginPrefix: "getstarted",
  //       loginSuffix: "login",
  //       ctaPrefix: "continuewith",
  //       ctaColor: "%23FF0000", // Red color
  //       ctaTextColor: "%23FF0000", // Red text
  //       btnShape: "rect",
  //       skipOption: "manualdetails",
  //       ttl: "600",
  //     };

  //     const queryParams = new URLSearchParams(queryParamsObject);
  //     const truecallerUrl = `${baseUrl}?${queryParams.toString()}`;

  //     console.log("Redirecting to Truecaller URL:", truecallerUrl);
  //     window.location.href = truecallerUrl;

  //     // Poll for authentication response
  //     const pollForAuthResponse = () => {
  //       const urlParams = new URLSearchParams(window.location.search);

  //       const receivedNonce = urlParams.get("requestNonce");

  //       if ( ) {
  //         console.log("Truecaller authentication successful!");

  //         // Proceed with login API call
  //         dispatch(asyncLoginWithTruecaller({ reqId: receivedNonce }))
  //           .then((result) => {
  //             if (asyncLoginWithTruecaller.fulfilled.match(result)) {
  //               console.log("Login API call successful:", result.payload);
  //               window.location.href=
  //               // Handle success (e.g., update UI, navigate to another page)
  //             } else {
  //               console.error("Login API call failed:", result.payload);
  //               // Handle failure (e.g., show an error message)
  //             }
  //           })
  //           .catch((error) => {
  //             console.error("Unexpected error during API call:", error);
  //           });
  //       } else {
  //         // Continue polling
  //         setTimeout(pollForAuthResponse, 1000);
  //       }
  //     };

  //     setTimeout(pollForAuthResponse, 1000); // Start polling after a 1-second delay
  //   };

  //   handleTruecaller();
  // }, [dispatch]);


  const handleSendOtp = async () => {
    if (phoneNumber.length === 10) {
      try {
        setLoading(true);
        setError(null);
        setOtpMethod("sms"); // Set OTP method to SMS
        await dispatch(sendOTP(phoneNumber)).unwrap();
        setIsOtpSent(true);
        console.log("OTP sent to phone number:", phoneNumber);
      } catch (err) {
        console.error("Error sending OTP:", err);
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please enter a valid 10-digit phone number.");
    }
  };

  const handleSendOtpToWhatsApp = async () => {
    if (phoneNumber.length === 10) {
      try {
        setLoading(true);
        setError(null);
        setOtpMethod("whatsapp"); // Set OTP method to WhatsApp
        await dispatch(loginUsingWhatsApp({ number: phoneNumber }));
        setIsOtpSent(true);
        console.log("OTP sent to WhatsApp number:", phoneNumber);
      } catch (err) {
       
        console.error("Error sending OTP to WhatsApp:", err);
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please enter a valid 10-digit phone number.");
    }
  };

  const handleLogin = async (otpCode = otp.join("")) => {
    if (otpCode.length === 6) {
      try {
        setLoading(true);
        setError(null);
        await dispatch(
          loginUsingOTP({ number: phoneNumber, otp: otpCode, navigate })
        ).unwrap();
window.location.href='/'
        console.log("OTP verified successfully.");
      } catch (err) {
        console.error("Error verifying OTP:", err);
      } finally {
        setLoading(false);
      }
    } else {
      setError("Invalid OTP. Please enter a 6-digit OTP.");
    }
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    if (/^\d$/.test(value) || value === "") {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }

      if (updatedOtp.join("").length === 6) {
        handleLogin(updatedOtp.join(""));
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white shadow-xl rounded-lg p-8 w-full max-w-md">
        <h2 className="text-2xl text-center font-semibold mb-6 text-[#3f0e9e]">
          Log In or Create Account Using OTP
        </h2>

        {error && (
          <div className="text-red-600 bg-red-100 p-3 mb-4 rounded-md text-center">
            {error}
          </div>
        )}

        <div className="mb-5">
          <label className="block text-gray-700 mb-1">Phone Number</label>
          <input
            type="tel"
            placeholder="Enter your phone number"
            className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm"
            value={phoneNumber} // Trigger handleTruecaller on click
            onChange={(e) =>
              setPhoneNumber(e.target.value.replace(/\D/g, "").slice(0, 10))
            }
            maxLength={10}
            disabled={isOtpSent}
          />
        </div>

        {isOtpSent && (
          <div className="mb-5">
            <label className="block text-gray-700 mb-1">OTP</label>
            <div className="flex justify-between">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  id={`otp-input-${index}`}
                  type="tel"
                  maxLength={1}
                  className="w-10 text-center px-2 py-2 border border-gray-300 rounded-md shadow-sm"
                  value={digit}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </div>
          </div>
        )}

        <div className="text-center">
          {!isOtpSent ? (
            <>
              <button
                className="w-full bg-violet-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-violet-700 transition duration-200"
                onClick={handleSendOtp}
                disabled={loading || otpMethod === "whatsapp"}
              >
                {loading && otpMethod === "sms" ? "Sending OTP..." : "Send OTP"}
              </button>

              <button
                className="mt-3 w-full bg-green-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-green-700 transition duration-200"
                onClick={handleSendOtpToWhatsApp}
                disabled={loading || otpMethod === "sms"}
              >
                {loading && otpMethod === "whatsapp"
                  ? "Sending OTP to WhatsApp..."
                  : "Send OTP to WhatsApp"}
              </button>
            </>
          ) : (
            <button
              className="w-full bg-violet-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-violet-700 transition duration-200"
              onClick={() => handleLogin(otp.join(""))}
              disabled={loading}
            >
              {loading ? "Verifying OTP..." : "Verify OTP"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginWithOtp;
