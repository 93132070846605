import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../header/SidebarForMobile";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="bg-transparent text-black py-8 mt-[2rem] sm:mt-[1rem] max-w-[100%] flex flex-col justify-center items-center mx-0 my-auto">
      <div className="mx-0 my-auto w-[90%] flex sm:flex-row flex-wrap justify-between">
        <div className="basis-[14%] sm:basis-[48%] mb-6 lg:mb-0 ">
          <h2 className="text-s sm:text-sm font-bold mb-4 text-black">
            Get to Know Us
          </h2>
          <ul>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/about">About Us</Link>
            </li>
            <li className="mb-3 cursor-pointer hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/contact">Contact Us</Link>
            </li>

            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/sitemap">Sitemap</Link>
            </li>
          </ul>
        </div>
        {/* 
        <div className="basis-[14%]  sm:basis-[48%] mb-6 lg:mb-0 ">
          <h2 className="text-s sm:text-sm font-bold mb-4 text-black">
            Customer Service
          </h2>
          <ul>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/">Cayro Business</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/">More Programs</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/">Cayro Advertising</Link>
            </li>
          </ul>
        </div> */}
        <div className="basis-[14%]  sm:basis-[48%] mb-6 lg:mb-0 ">
          <h2 className="text-s sm:text-sm font-bold mb-4 text-black">
            Orders & Returns
          </h2>
          <ul>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/return-and-refund">Return & Refund</Link>
            </li>
            <li className="mb-3 hover:underline  text-sm sm:text-xs hover:text-black">
              <Link to="/shipping-and-delivery">Shipping And Delivery</Link>
            </li>
            <li className="mb-3 hover:underline  text-sm sm:text-xs hover:text-black">
              <Link to="/terms-and-condition">Terms And Condition</Link>
            </li>
          </ul>
        </div>
        <div className="basis-[14%]  sm:basis-[48%] mb-6 lg:mb-0 ">
          <h2 className="text-s sm:text-sm font-bold mb-4 text-black">
            Shopping
          </h2>
          <ul>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/wishlist">Wishlist</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/">Shop by Brand</Link>
            </li>
            {/* <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/blogs">Blogs</Link>
            </li> */}
            {/* <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/">Our Apps</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/">Offers</Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="/">Size Guide</Link>
            </li> */}
          </ul>
        </div>
        <div className="basis-[14%]  sm:basis-[48%] mb-6 lg:mb-0 ">
          <h2 className="text-s sm:text-sm font-bold mb-4 text-black">
            Connect
          </h2>
          <ul>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="https://www.instagram.com/_cayroshop/">
                <span className="[&>svg]:h-5 [&>svg]:w-5 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 448 512"
                  >
                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                  </svg>
                  <span className="ml-[0.5rem]">Instagram</span>
                </span>
              </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="https://www.facebook.com/profile.php?id=61558680970395">
                <span className="[&>svg]:h-5 [&>svg]:w-5 flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 320 512"
                  >
                    <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                  </svg>
                  <span className="ml-[0.5rem]">Facebook</span>
                </span>
              </Link>
            </li>
            <li className="mb-3 hover:underline text-sm sm:text-xs hover:text-black">
              <Link to="https://x.com/cayroshop_">
                <span className="[&>svg]:h-5 [&>svg]:w-5 flex items-center">
                  <FaXTwitter />
                  <span className="ml-[0.5rem]">Twitter</span>
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="text-center text-sm sm:text-xs mt-6">
        <p>
          &copy; 2024 &nbsp;
          <Link to="/">
            <span className="text-[#3f0e9e] hover:underline">
              Cayro Enterprises
            </span>
          </Link>
          &nbsp; All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
