import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import {
  updateQuantity,
  removeProduct,
  clearCart,
  allProduct,
  getAllProductInCart,
  setProductInCart,
  setCartLength,
  clearFromLocalStorage,
} from "../../slice/CartSlice";
import Lottie from "lottie-react";
import carticon from "../../loti/carticon.json";
import emptyCart from "../../loti/emptyCart.json";
import { IsLoggedIn, UserInfo } from "../../slice/UserSlice";
import { PostRequest } from "../../api/Request";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import Loader from "../../common/loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ShoppingCart: any = () => {
  const { flatTenPercentOff, subtotal, shippingCharges, total, totalTax } =
    useSelector((state: RootState) => state.cart);
  const [isLoadingForCheckingOut, setIsLoadingForCheckingOut] =
    useState<boolean>(false);
  const userDetail: any = useSelector(UserInfo);
  const [paymentMethod, setPaymentMethod] = useState<string>("CC Avenue");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const allProducts = useSelector(allProduct);
  const userIsLoggedIn = useSelector(IsLoggedIn);
  const [shippingAddress, setShippingAddress] = useState<any>();
  const { addresses } = useSelector(UserInfo);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [percentageOff, setPercentageOff] = useState(0);
  // const [isFetchingCart, setIsFetchingCart] = useState<boolean>(false);
  const { products, loading, error } = useSelector(
    (state: RootState) => state.cart
  );
  // New state to track loading for each product
  const [loadingProducts, setLoadingProducts] = useState<{
    [key: string]: boolean;
  }>({});
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    dispatch(getAllProductInCart());
    
  }, []);

  const handleIncrementForQuantity = async (product: any, index: number) => {
    setIsLoading(true);
    let updateAllProduct = [...allProducts];
    setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));
    
    try {
      const token = localStorage.getItem("userToken"); // Check for token
    
      if (!token) {
        // User is not logged in, update quantity in localStorage
        const newQuantity = Math.min(
          updateAllProduct[index].quantityByUser + 1,
          updateAllProduct[index].totalStock
        );
  
        // Update localStorage directly
        const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
        const updatedCart = currentCart.map((item: any) => {
          if (item._id === product._id) {
            return {
              ...item,
              quantityByUser: newQuantity,
              total: newQuantity * product.salePrice,
            };
          }
          return item;
        });
        localStorage.setItem("cart", JSON.stringify(updatedCart));
  
        // Dispatch the action to update the product in Redux store
        dispatch(
          setProductInCart({
            product: {
              ...product,
              quantityByUser: newQuantity,
              total: newQuantity * product.salePrice,
            },
            index,
          })
        );
  
        // Update cart length in Redux
        dispatch(setCartLength(updatedCart.length));
        
  
        return; // Exit early if not logged in
      }
  
      // User is logged in, proceed with API call
      const response = await PostRequest("/updateItemQuantity", {
        productId: product?._id,
        quantity: product?.quantityByUser + 1,
      });
  
      if (response.status === 200) {
        const newQuantity = Math.min(
          updateAllProduct[index].quantityByUser + 1,
          updateAllProduct[index].totalStock
        );
  
        dispatch(
          setProductInCart({
            product: {
              ...product,
              quantityByUser: newQuantity,
              total: newQuantity * product.salePrice,
            },
            index,
          })
        );
  
        // Update localStorage
        const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
        const updatedCart = currentCart.map((item: any) => {
          if (item._id === product._id) {
            return {
              ...item,
              quantityByUser: newQuantity,
              total: newQuantity * product.salePrice,
            };
          }
          return item;
        });
        localStorage.setItem("cart", JSON.stringify(updatedCart));
  
        // Update cart length in Redux
        dispatch(setCartLength(updatedCart.length));
      }
    } catch (error: any) {
      showErrorToast(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
    }
  };
  
  const handleDecrementForQuantity = async (product: any, index: number) => {
    setIsLoading(true);
    setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));
  
    try {
      const token = localStorage.getItem("userToken"); // Check for token
  
      if (!token) {
        if (product.quantityByUser === 1) {
          const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const updatedCart = currentCart.filter((item: any) => item._id !== product._id);
          localStorage.setItem("cart", JSON.stringify(updatedCart));
  
          dispatch(
            setProductInCart({
              product: false,
              index,
            })
          );
  
          // Update cart length in Redux
          dispatch(setCartLength(updatedCart.length));
  
          return; // Exit early if removing item
        }
  
        const newQuantity = product.quantityByUser - 1;
        const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
        const updatedCart = currentCart.map((item: any) => {
          if (item._id === product._id) {
            return {
              ...item,
              quantityByUser: newQuantity,
              total: newQuantity * product.salePrice,
            };
          }
          return item;
        });
        localStorage.setItem("cart", JSON.stringify(updatedCart));
  
        dispatch(
          setProductInCart({
            product: {
              ...product,
              total: newQuantity * product.salePrice,
              quantityByUser: newQuantity,
            },
            index,
          })
        );
  
        dispatch(setCartLength(updatedCart.length));
  
        return; // Exit early if not logged in
      }
  
      if (product.quantityByUser === 1) {
        const response = await PostRequest("/removeItemFromCart", {
          productId: product?._id,
        });
  
        if (response.status === 200) {
          dispatch(
            setProductInCart({
              product: false,
              index,
            })
          );
  
          const cartLength = response.data.cart.items.length;
          dispatch(setCartLength(cartLength));
  
          const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const updatedCart = currentCart.filter((item: any) => item._id !== product._id);
          localStorage.setItem("cart", JSON.stringify(updatedCart));
        }
      } else {
        const response = await PostRequest("/updateItemQuantity", {
          productId: product?._id,
          quantity: product.quantityByUser - 1,
        });
  
        if (response.status === 200) {
          const newQuantity = product.quantityByUser - 1;
  
          dispatch(
            setProductInCart({
              product: {
                ...product,
                total: newQuantity * product.salePrice,
                quantityByUser: newQuantity,
              },
              index,
            })
          );
  
          const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const updatedCart = currentCart.map((item: any) => {
            if (item._id === product._id) {
              return {
                ...item,
                quantityByUser: newQuantity,
                total: newQuantity * product.salePrice,
              };
            }
            return item;
          });
          localStorage.setItem("cart", JSON.stringify(updatedCart));
  
          dispatch(setCartLength(updatedCart.length));
        }
      }
    } catch (error: any) {
      showErrorToast(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
    }
  };
  

  const handleClearCart = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      // Dispatch the clearCart action
      await dispatch(clearCart());
      await dispatch(clearFromLocalStorage())    
      const cartItems = JSON.parse(localStorage.getItem("cart") || "[]");
      await dispatch(setCartLength(cartItems.length));
          } catch (error) {
      console.error("Error clearing cart:", error);
    } finally {
      setIsLoading(false); // Reset loading state after the action
    }
  };


  const handlePaymentMethodChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethod(() => e.target.value);
  };

  useEffect(() => {
    if (userDetail.addresses.length > 0) {
      setShippingAddress(() => {
        return userDetail.addresses[0];
      });
    }
  }, []);

  const createOrder = async () => {
    try {
      setIsLoadingForCheckingOut(true);
      let allProductDetail = allProducts.flatMap((product: any) => {
        return { _id: product._id, quantity: product.quantityByUser };
      });
      let response = await PostRequest("createOrder", {
        payment_mode: paymentMethod,
        discount: 0,
        shippingCharge: 0,
        totalAmount: subtotal,
        productDetails: allProductDetail,
        shippingAddress,
      });

      if (response.status === 200) {
        window.location.href = await response?.data?.payment_links?.web;
      }
    } catch (error) {
      showErrorToast("Order Failed");
    } finally {
      setIsLoadingForCheckingOut(() => false);
    }
  };

  if (loading) {
    <div className="wrapper max-w-[97%] mx-auto px-4 sm:px-6 lg:px-8 flex justify-center items-center flex-col">
      <Loader />
    </div>;
  }
  if (!loading) {
    return (
      <div
        style={{ marginTop: "7rem" }}
        className={
          allProducts?.length > 0
            ? "wrapper max-w-[97%] mx-auto px-4 sm:px-6 lg:px-8"
            : "wrapper max-w-[97%] mx-auto px-4 sm:px-6 lg:px-8 flex justify-center items-center flex-col"
        }
      >
        <h1 className="text-[1.8rem] sm:text-[1.3rem] sm:ml-[-8rem] mt-[-1rem] sm:mt-[-1rem] w-[100%] flex items-center">
          <Lottie
            animationData={carticon}
            style={{
              width: "80px",
              height: "100px",
              marginRight: "-1.4rem",
            }}
          />
          Cart
        </h1>
        {isLoading && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <Loader /> {/* Your loader component */}
        </div>
      )}
      
        {allProducts?.length > 0 ? (
          <div className="flex flex-row md:flex-col flex-wrap justify-between">
            <div className="w-full basis-[70%] md:w-9/12 pr-4 border-[1.3px] md:border-none rounded-lg">
              <table className=" mx-2  min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Products
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Product Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      M.R.P
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      GST
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Subtotal
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {allProducts?.length > 0 &&
                    allProducts?.map((product: any, index: number) => (
                      <tr key={product._id}>
                        <td className="px-6 py-4">
                          <Link
                            to={`/product?name=${product.productName}&product_id=${product._id}`}
                          >
                            <LazyLoadImage
                              effect="blur"
                              className="w-24 h-24 md:w-16 md:h-16 sm:w-12 sm:h-12 object-contain"
                              src={product?.images[0]?.url}
                              alt={product.productName}
                            />
                          </Link>
                        </td>
                        <td className="px-6 py-4 md:w-1/3">
                          <Link
                            to={`/product?name=${product.productName}&product_id=${product._id}`}
                            className="text-sm font-medium text-gray-900 md:text-[12px] sm:text-[8px]"
                          >
                            {product.productName}
                          </Link>
                        </td>
                        <td className="px-6 py-4">
                          <span className="text-sm font-medium text-gray-900">
                            ₹{product.regularPrice}
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          <span className="text-sm font-medium text-gray-900">
                            ₹{product.salePrice}
                          </span>
                        </td>
                        <td className="px-2 py-4 flex-row ">
                          <button
                            className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                            onClick={() =>
                              handleDecrementForQuantity(product, index)
                            }
                          >
                            <svg
                              className="flex-shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M5 12h14"></path>
                            </svg>
                          </button>
                          <input
                            type="number"
                            className="w-8 text-center border-gray-300 rounded-md"
                            value={product?.quantityByUser}
                            readOnly
                          />
                          <button
                            className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
                            onClick={() =>
                              handleIncrementForQuantity(product, index)
                            }
                          >
                            <svg
                              className="flex-shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path d="M5 12h14"></path>
                              <path d="M12 5v14"></path>
                            </svg>
                          </button>
                        </td>
                        <td className="px-6 py-4">
                          <span className="text-sm font-medium text-gray-900">
                            {product.gst}%
                          </span>
                        </td>
                        <td className="px-6 py-4">
                          <span className="text-sm font-medium text-gray-900">
                            ₹{product.total}
                          </span>
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td colSpan={8} className="px-6 py-4 text-right">
                      <button
                        className="ml-2 px-4 py-2 bg-red-600 rounded-lg text-white"
                        onClick={handleClearCart}
                      >
                        Clear Cart
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Sidebar Content */}
            <div className="md:w-full basis-[18%] max-h-[400px] w-4/12 md:mt-4  right-bar flex-1 rounded-lg  md:mt-0 border-[1px] ml-2 ">
              <div className="flex flex-col space-y-4 mb-4">
                {/* <div className="flex items-center space-x-4 mr-5">
                <label htmlFor="coupon_code" className="sr-only">
                  Coupon:
                </label>
                <input
                  type="text"
                  name="coupon_code"
                  className="input-text p-[0.8rem] text-sm border border-gray-300 rounded flex-grow"
                  placeholder="Coupon code"
                />
                <button
                  type="submit"
                  className="bg-primary  md:p-2 text-xs text-white p-[0.8rem] rounded mr-2"
                  name="apply_coupon"
                >
                  Apply
                </button>
              </div> */}
              </div>
              {/* <div className="mb-4 mx-5">
              <p className="mt-4 text-sm">
                <strong className="text-[0.96rem] mb-[0.4rem]">
                  Shipping Address:
                  <br />
                </strong>
                {userDetail.addresses.length > 0 && (
                  <div>
                    <span className=" text-[15px]">
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ].name + ", "}
                    </span>
                    <span className=" text-[15px]">
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.mobileNumber + ", "}
                      ,
                    </span>
                    <span>
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.address1 + ", "}
                      ,{" "}
                    </span>
                    <span>
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.address2 + ", "}{" "}
                    </span>
                    <span>
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.city + ", "}{" "}
                    </span>
                    <span>
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.state + ", "}{" "}
                    </span>
                    <span>
                      {userDetail.addresses[
                        localStorage.getItem("selectedAddressIndex")
                          ? Number(localStorage.getItem("selectedAddressIndex"))
                          : 0
                      ]?.postalCode + ", "}{" "}
                    </span>
                    <span>
                      {
                        userDetail.addresses[
                          localStorage.getItem("selectedAddressIndex")
                            ? Number(
                                localStorage.getItem("selectedAddressIndex")
                              )
                            : 0
                        ]?.country
                      }{" "}
                    </span>
                  </div>
                )}
                {userDetail.addresses.length > 0 && (
                  <Link to="/list-of-address" className="text-blue-700">
                    Change Address
                  </Link>
                )}
              </p>
              <div className="mt-[0.8rem]">
                <p className="font-bold">Payment Mode :</p>
                <label className="cursor-pointer text-blue-600 block mt-[0.5rem] text-[0.90rem] text-sm font-[450]">
                  <input
                    type="radio"
                    name="payment-method"
                    value="CC Avenue"
                    checked={paymentMethod === "CC Avenue" ? true : false}
                    onChange={handlePaymentMethodChange}
                    className="mr-[0.4rem] h-[0.68rem]"
                  />
                  CC Avenue powered by HDFC
                </label>
              </div>
              <br />
            </div> */}

              <div className="m-4">
                {/* <p className="flex justify-between text-sm mb-3">
                  <span className="font-medium text-normal">
                    Total amount ( M.R.P )
                  </span>
                  <span>₹{totalAmount.toFixed(2)}</span>
                </p>
                <hr className="mb-3" />
                <p className="flex justify-between text-sm mb-3">
                  <span className="font-medium text-normal text-green-600">
                    Discount ({percentageOff.toFixed(2)}%)
                  </span>
                  <span className="text-green-600">
                    -₹{discountAmount.toFixed(2)}
                  </span>
                </p>
                <hr className="mb-3" /> */}

                <p className="flex justify-between text-sm mb-3">
                  <span className="font-medium text-normal">Subtotal</span>
                  <span>₹{subtotal?.toFixed(2)}</span>
                </p>
                <hr className="mb-3" />
                {flatTenPercentOff ? (
                  <>
                    <p className="flex justify-between text-sm mb-3">
                      <span className="font-medium text-normal text-green-600">
                        Flat 10% off above 1 Lakh
                      </span>
                      <span className="text-green-600">
                        -₹{flatTenPercentOff.toFixed(2)}
                      </span>
                    </p>
                    <hr className="mb-3" />
                  </>
                ) : (
                  ""
                )}
                <p className="flex justify-between text-sm mb-3">
                  <span className="font-medium text-normal">Total Tax</span>
                  <span>₹{totalTax?.toFixed(2)}</span>
                </p>
                <hr className="mb-3" />
                <p className="flex justify-between text-sm mb-3">
                  <span className="font-medium text-normal">
                    Shipping Charges
                  </span>
                  <span>
                    <span className="line-through">
                      ₹{shippingCharges.toFixed(2)}
                    </span>
                    <span className="ml-[0.5rem]">₹0</span>
                  </span>
                </p>
                <hr className="mb-3" />
                <p className="flex justify-between text-sm mb-3">
                  <span className="font-medium text-normal">Grand Total</span>
                  <span>₹{total.toFixed(2)}</span>
                </p>
              </div>
              <Link
                to="/checkout"
                // onClick={handleClickToCheckout}
                className="w-[80%]  text-sm cursor-pointer flex items-center justify-center bg-primary text-white py-2 px-2 rounded-md  text-md w-84 m-auto relative"
              >
                Proceed to Checkout
                {isLoadingForCheckingOut && (
                  <div className="absolute top-[-18px] sm:static sm:text-center sm:w-[100%] sm:flex sm:justify-center sm:items-center sm:h-[50vh] sm:left-[45%] right-[21%]">
                    <Loader />
                  </div>
                )}
              </Link>

              <br />
            </div>
          </div>
        ) : (
          <>
            <Lottie
              animationData={emptyCart}
              loop={true}
              style={{
                width: "20rem",
                height: "20rem",
              }}
              className="w-[20rem] h-[20rem] sm:w-[12rem] sm:h-[12rem]"
            />
            <p className="text-red-400 text-lg sm:text-[0.9rem]">
              No items in your cart
            </p>
          </>
        )}
      </div>
    );
  }
};

export default ShoppingCart;
