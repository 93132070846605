import Lottie from "lottie-react";
import React, { useState, useEffect } from "react";
import carticon from "../../loti/carticon.json";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../store/store";
import {
  updateQuantity,
  removeProduct,
  clearCart,
  allProduct,
  setProductInCart,
  getAllProductInCart,
  setCartLength,
  clearFromLocalStorage,
} from "../../slice/CartSlice";
import emptyCart from "../../loti/emptyCart.json";
import { IsLoggedIn, UserInfo } from "../../slice/UserSlice";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import { PostRequest } from "../../api/Request";
import Loader from "../../common/loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MobileCart: React.FC = () => {
  const {
    flatTenPercentOff,
    products,
    subtotal,
    discount,
    shippingCharges,
    total,
    totalTax,
  } = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch<AppDispatch>();
  const allProducts = useSelector(allProduct);
  const [isLoadingForCheckingOut, setIsLoadingForCheckingOut] =
    useState<boolean>(false);
  const userDetail: any = useSelector(UserInfo);
  const [paymentMethod, setPaymentMethod] = useState<string>("CC Avenue");
  const navigate = useNavigate();
  const [showShippingCalculator, setShowShippingCalculator] = useState(false);
  const userIsLoggedIn = useSelector(IsLoggedIn);
  const { addresses } = useSelector(UserInfo);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [percentageOff, setPercentageOff] = useState(0);
  const [shippingAddress, setShippingAddress] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  // New state to track loading for each product
  const [loadingProducts, setLoadingProducts] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    // Calculate total amount

    let newTotalAmount = 0;
    allProducts.forEach((product: any) => {
      newTotalAmount += product?.regularPrice * (product?.quantityByUser || 0);
    });

    // Assuming subtotal is defined elsewhere or calculated dynamically
    const newSubtotal = subtotal; // Replace with actual logic to get subtotal

    // Calculate discount amount and percentage off
    const newDiscountAmount = newTotalAmount - newSubtotal;
    const newPercentageOff = (newDiscountAmount / newTotalAmount) * 100;

    // Update state
    setTotalAmount(() => newTotalAmount);
    setDiscountAmount(newDiscountAmount);
    setPercentageOff(newPercentageOff);
  }, [allProducts, subtotal]);

  useEffect(() => {
    dispatch(getAllProductInCart());
  }, []);
  
  const handleIncrementForQuantity = async (product: any, index: number) => {
    setIsLoading(true);
    let updateAllProduct = [...allProducts];
    setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));
    
    try {
      const token = localStorage.getItem("userToken"); // Check for token
    
      if (!token) {
        // User is not logged in, update quantity in localStorage
        const newQuantity = Math.min(
          updateAllProduct[index].quantityByUser + 1,
          updateAllProduct[index].totalStock
        );
  
        // Update localStorage directly
        const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
        const updatedCart = currentCart.map((item: any) => {
          if (item._id === product._id) {
            return {
              ...item,
              quantityByUser: newQuantity,
              total: newQuantity * product.salePrice,
            };
          }
          return item;
        });
        localStorage.setItem("cart", JSON.stringify(updatedCart));
  
        // Dispatch the action to update the product in Redux store
        dispatch(
          setProductInCart({
            product: {
              ...product,
              quantityByUser: newQuantity,
              total: newQuantity * product.salePrice,
            },
            index,
          })
        );
  
        // Update cart length in Redux
        // dispatch(setCartLength(updatedCart.length));
  
        return; // Exit early if not logged in
      }
  
      // User is logged in, proceed with API call
      const response = await PostRequest("/updateItemQuantity", {
        productId: product?._id,
        quantity: product?.quantityByUser + 1,
      });
  
      if (response.status === 200) {
        const newQuantity = Math.min(
          updateAllProduct[index].quantityByUser + 1,
          updateAllProduct[index].totalStock
        );
  
        dispatch(
          setProductInCart({
            product: {
              ...product,
              quantityByUser: newQuantity,
              total: newQuantity * product.salePrice,
            },
            index,
          })
        );
  
        // Update localStorage
        const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
        const updatedCart = currentCart.map((item: any) => {
          if (item._id === product._id) {
            return {
              ...item,
              quantityByUser: newQuantity,
              total: newQuantity * product.salePrice,
            };
          }
          return item;
        });
        localStorage.setItem("cart", JSON.stringify(updatedCart));
  
        // Update cart length in Redux
        // dispatch(setCartLength(updatedCart.length));
      }
    } catch (error: any) {
      showErrorToast(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
    }
  };
  
  const handleDecrementForQuantity = async (product: any, index: number) => {
    setIsLoading(true);
    setLoadingProducts((prev) => ({ ...prev, [product._id]: true }));
  
    try {
      const token = localStorage.getItem("userToken"); // Check for token
  
      if (!token) {
        if (product.quantityByUser === 1) {
          const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const updatedCart = currentCart.filter((item: any) => item._id !== product._id);
          localStorage.setItem("cart", JSON.stringify(updatedCart));
  
          dispatch(
            setProductInCart({
              product: false,
              index,
            })
          );
  
          // Update cart length in Redux
          dispatch(setCartLength(updatedCart.length));
  
          return; // Exit early if removing item
        }
  
        const newQuantity = product.quantityByUser - 1;
        const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
        const updatedCart = currentCart.map((item: any) => {
          if (item._id === product._id) {
            return {
              ...item,
              quantityByUser: newQuantity,
              total: newQuantity * product.salePrice,
            };
          }
          return item;
        });
        localStorage.setItem("cart", JSON.stringify(updatedCart));
  
        dispatch(
          setProductInCart({
            product: {
              ...product,
              total: newQuantity * product.salePrice,
              quantityByUser: newQuantity,
            },
            index,
          })
        );
  
        // dispatch(setCartLength(updatedCart.length));
  
        return; // Exit early if not logged in
      }
  
      if (product.quantityByUser === 1) {
        const response = await PostRequest("/removeItemFromCart", {
          productId: product?._id,
        });
  
        if (response.status === 200) {
          dispatch(
            setProductInCart({
              product: false,
              index,
            })
          );
  
          const cartLength = response.data.cart.items.length;
          dispatch(setCartLength(cartLength));
  
          const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const updatedCart = currentCart.filter((item: any) => item._id !== product._id);
          localStorage.setItem("cart", JSON.stringify(updatedCart));
        }
      } else {
        const response = await PostRequest("/updateItemQuantity", {
          productId: product?._id,
          quantity: product.quantityByUser - 1,
        });
  
        if (response.status === 200) {
          const newQuantity = product.quantityByUser - 1;
  
          dispatch(
            setProductInCart({
              product: {
                ...product,
                total: newQuantity * product.salePrice,
                quantityByUser: newQuantity,
              },
              index,
            })
          );
  
          const currentCart = JSON.parse(localStorage.getItem("cart") || "[]");
          const updatedCart = currentCart.map((item: any) => {
            if (item._id === product._id) {
              return {
                ...item,
                quantityByUser: newQuantity,
                total: newQuantity * product.salePrice,
              };
            }
            return item;
          });
          localStorage.setItem("cart", JSON.stringify(updatedCart));
  
          // dispatch(setCartLength(updatedCart.length));
        }
      }
    } catch (error: any) {
      showErrorToast(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      setLoadingProducts((prev) => ({ ...prev, [product._id]: false }));
    }
  };
  

  const handleClearCart = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      // Dispatch the clearCart action
      await dispatch(clearCart());
      await dispatch(clearFromLocalStorage())
      const cartItems = JSON.parse(localStorage.getItem("cart") || "[]");
      await dispatch(setCartLength(cartItems.length));
      
    } catch (error) {
      console.error("Error clearing cart:", error);
    } finally {
      setIsLoading(false); // Reset loading state after the action
    }
    
  };

  const createOrder = async () => {
    try {
      setIsLoadingForCheckingOut(true);
      let allProductDetail = allProducts.flatMap((product: any) => {
        return { _id: product._id, quantity: product.quantityByUser };
      });
      let response = await PostRequest("createOrder", {
        payment_mode: paymentMethod,
        discount: 0,
        shippingCharge: 0,
        totalAmount: subtotal,
        productDetails: allProductDetail,
        shippingAddress,
      });

      if (response.status === 200) {
        window.location.href = await response?.data?.payment_links?.web;
      }
    } catch (error) {
      showErrorToast("Order Failed");
    } finally {
      setIsLoadingForCheckingOut(() => false);
    }
  };

  const handleClickToCheckout = async () => {
    if (userIsLoggedIn == false) {
      navigate(`/register?redirect=cart`);
    } else if (!userDetail.email) {
      navigate(`/profile?redirect=cart`);
      return showErrorToast("Please add your email");
    }
    if (userIsLoggedIn && addresses.length === 0) {
      navigate(`/list-of-address?redirect=cart`);
      return showErrorToast("Add your delivery address");
    }
    if (userIsLoggedIn && paymentMethod) await createOrder();
    if (userIsLoggedIn && !paymentMethod) showErrorToast("Select payment mode");
  };

  const handlePaymentMethodChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPaymentMethod(() => e.target.value);
  };

  useEffect(() => {
    if (userDetail.addresses.length > 0) {
      setShippingAddress(() => {
        return userDetail.addresses[0];
      });
    }
  }, []);

  return (
    <div
      className={
        allProducts?.length > 0
          ? "wrapper max-w-[100%] mt-[8rem] mx-auto px-4 sm:px-4 "
          : "wrapper max-w-[100%] mt-[8rem] mx-auto px-4 sm:px-4 flex justify-center items-center flex-col"
      }
    >
         {isLoading && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <Loader /> {/* Your loader component */}
        </div>
      )}
      <h1 className="text-[1.8rem] sm:text-[1.3rem] sm:ml-[-1.8rem] sm:mt-[-3rem] w-[100%] flex items-center">
        <Lottie
          animationData={carticon}
          style={{
            width: "80px",
            height: "100px",
            marginRight: "-1.4rem",
          }}
        />
        Cart
      </h1>
      <div className="widget_shopping_cart_content mt-[-2rem]">
        {allProducts.length > 0 ? (
          allProducts.map((product: any, index: number) => (
            <div
              key={product._id}
              className="woocommerce-mini-cart-item mini-cart-item flex items-center space-x-4 py-4 border-b border-gray-200"
            >
              <div className="woocommerce-mini-cart-item__thumbnail">
                <Link
                  to={`/product?name=${product.productName}&product_id=${product._id}`}
                >
                  <LazyLoadImage
                    effect="blur"
                    src={product?.images[0]?.url}
                    alt={product.productName}
                    className="w-28 h-28 md:w-24 md:h-24 object-contain rounded-lg shadow-sm object-contain"
                  />
                </Link>
              </div>
              <div className="flex-1">
                <div className="flex justify-between items-center">
                  {/* <div className="woocommerce-mini-cart-item__name text-xs font-small text-gray-900"> */}
                  <div className="woocommerce-mini-cart-item__name text-sm font-medium text-gray-900 leading-snug">
                    <Link
                      to={`/product?name=${product.productName}&product_id=${product._id}`}
                      className="line-clamp-3"
                    >
                      {product.productName}
                    </Link>
                  </div>
                </div>
                <div className="text-xs text-gray-500">
                  <p>
                    <a className="wcfm_dashboard_item_title" target="_blank">
                      ₹{product.salePrice}
                    </a>
                  </p>
                </div>
                <div className="flex items-center mt-2">
                  <div className="quantity flex items-center space-x-1">
                    <button
                      // className="text-gray-500 text-xs border-2 border-gray-400 p-1 "
                      className="text-gray-500 text-sm border border-gray-400 rounded-full w-6 h-6 flex justify-center items-center"
                      onClick={() => handleDecrementForQuantity(product, index)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      // className="w-4 text-center border-gray-300 rounded-md text-xs"
                      className="w-10 text-center border-gray-300 rounded-md text-sm"
                      value={product.quantityByUser}
                      readOnly
                    />
                    <button
                      onClick={() => handleIncrementForQuantity(product, index)}
                      // className="text-gray-500 text-xs border-2 border-gray-400 p-1"
                      className="text-gray-500 text-sm border border-gray-400 rounded-full w-6 h-6 flex justify-center items-center"
                    >
                      +
                    </button>
                  </div>
                  {/* <div className="ml-4 ">
                    <button
                      className="remove remove_from_cart_button text-red-600 hover:text-red-900 p-1"
                      aria-label="Remove this item"
                      data-product_id={String(product.id)}
                      data-cart_item_key={String(product.id)}
                      onClick={() => dispatch(removeProduct(product._id))}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        className="w-6 h-6"
                      >
                        <path d="M6 21h12c1.1 0 2-.9 2-2V7H4v12c0 1.1.9 2 2 2zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                      </svg>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <Lottie
              animationData={emptyCart}
              loop={true}
              style={{
                width: "20rem",
                height: "20rem",
              }}
              className="w-[20rem] sm:mt-[-2rem] h-[20rem] sm:w-[12rem] sm:h-[12rem]"
            />
            <p className="text-red-400 text-lg text-center sm:text-[0.9rem]">
              No items in your cart
            </p>
          </>
        )}

        {/* {allProducts.length > 0 && (
          <div className="flex justify-center items-center mt-2">
            <button
              className="bg-red-600 text-white text-sm p-2 w-[30%] rounded-lg shadow-lg hover:bg-red-700 transition duration-300 ease-in-out"
              onClick={() => dispatch(clearCart())}
            >
              Clear Cart
            </button>
          </div>
        )} */}
      </div>
      {allProducts.length > 0 && (
        <div className="md:w-full basis-[18%] max-h-[500px] w-4/12 md:mt-4 pt-[2rem] right-bar flex-1 rounded-lg  md:mt-0 ">
          <div>
            {/* <p className="flex justify-between text-sm mb-3">
              <span className="font-medium text-normal">Total amount</span>
              <span>₹{totalAmount.toFixed(2)}</span>
            </p>
            <hr className="mb-3" />
            <p className="flex justify-between text-sm mb-3">
              <span className="font-medium text-normal text-green-600">
                Discount ({percentageOff.toFixed(2)}%)
              </span>
              <span className="text-green-600">
                -₹{discountAmount.toFixed(2)}
              </span>
            </p>
            <hr className="mb-3" /> */}
            <p className="flex justify-between text-sm mb-3">
              <span>Subtotal</span>
              <span>₹{subtotal.toFixed(2)}</span>
            </p>
            <hr className="mb-3" />
            {flatTenPercentOff ? (
              <>
                <p className="flex justify-between text-sm mb-3">
                  <span className="font-medium text-normal text-green-600">
                    10% Off Orders above 1Lakh
                  </span>
                  <span className="text-green-600">
                    -₹{flatTenPercentOff.toFixed(2)}
                  </span>
                </p>
                <hr className="mb-3" />
              </>
            ) : (
              ""
            )}
            <p className="flex justify-between text-sm mb-3">
              <span className="font-medium text-normal">Total Tax</span>
              <span>₹{totalTax?.toFixed(2)}</span>
            </p>
            <hr className="mb-3" />
            <p className="flex justify-between text-sm mb-3">
              <span>Shipping Charges</span>
              <span>
                <span className="line-through">
                  ₹{shippingCharges.toFixed(2)}
                </span>
                <span className="ml-[0.5rem]"> ₹0</span>
              </span>
            </p>

            <hr className="mb-3" />
            <p className="flex justify-between text-sm mb-3">
              <span>Total</span>
              <span>₹{total.toFixed(2)}</span>
            </p>
          </div>

                 <div className="w-full flex  justify-end h-[100px] mt-[50px]">
                 <button
                      className="p-2 flex items-center bg-red-600 text-white rounded-[10px] h-[30px]"
                      onClick={handleClearCart}
                    >
                      Clear Cart
                    </button>
                 </div>
                  

                    

     
          <Link
            to="/checkout"
            className="w-[80%] text-sm cursor-pointer flex items-center justify-center bg-primary text-white py-2 px-2 rounded-lg text-md w-84 m-auto relative"
          >
            Checkout
          </Link>

          <br />
        </div>
      )}
    </div>
  );
};

export default MobileCart;
