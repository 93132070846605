import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PostRequest } from "../../api/Request";
import { showErrorToast, showSuccessToast } from "../../utils/toast";

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // Handle form input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  // Handle form submission
  const handleContactForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await PostRequest("/contactUsForm", formData); // Your backend API endpoint
      if (response.status === 200) {
        showSuccessToast("Message sent successfully!");
        // Reset form
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      }
    } catch (error: any) {
      showErrorToast(error.response?.data || "Failed to send message.");
    }
  };

  return (
    <>
      <div className=" relative px-8  mt-[7rem] justify-left items-center">
        <div
          className="w-[3.5rem] h-[3.5rem] rounded-full static"
          style={{ backgroundColor: "rgba(255, 230, 189)" }}
        >
          <p className="absolute pl-[50] bottom-0 left-14  text-[1.9rem] text-green-500 font-serif font-extrabold">
            Contact Us
          </p>
        </div>
      </div>

      <div className="mx-auto w-[80%] sm:w-[95%] mt-2 ">
        <div className="flex sm:flex-col border border-blue-200 rounded-2xl p-2 ">
          {/* Image on the left */}
          <div className="flex sm:w-[100%]">
            <img
              src={`${process.env.PUBLIC_URL}/contactImage.png`}
              alt="Contact"
              className="w-full h-[450px] lg:h-[250px] rounded-lg"
            />
          </div>

          {/* Form on the right */}
          <div className="mt-4 sm:mt-0 mx-auto justify-evenly">
            <h3 className="m-5 text-2xl">Drop Us A Message For Any Query</h3>
            <form
              className="flex flex-col space-y-4"
              onSubmit={handleContactForm}
            >
              <label className="text-gray-700" htmlFor="name">
                Name:
                <input
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="border rounded-lg p-2 w-full"
                  placeholder="Enter your name"
                  required
                />
              </label>
              <label className="text-gray-700" htmlFor="email">
                Email:
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="border rounded-lg p-2 w-full"
                  placeholder="Enter your email"
                  required
                />
              </label>
              <label className="text-gray-700" htmlFor="message">
                Message:
                <textarea
                  id="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="border rounded-lg p-2 w-full"
                  placeholder="Type your message here"
                  required
                />
              </label>
              <button
                type="submit"
                className="bg-blue-500 text-white rounded-lg p-2 hover:bg-blue-600"
              >
                Send
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Rest of the code remains unchanged */}
      <main className="container flex-col mx-auto m-5 lg:justify-between lg:items-start">
        {/* Support, Feedback, Media Inquiries Section */}
        <div className="mt-10 w-[80%] sm:w-[95%] mx-auto">
          <div className="space-y-8 p-10 border  border-blue-200 rounded-xl">
            <div>
              <h2 className="text-xl font-bold text-red-800">
                Customer Support
              </h2>
              <p className="mt-2 text-gray-600">
                Our support team is available around the clock to address any
                concerns or queries you may have.
              </p>
            </div>

            <div>
              <h2 className="text-xl font-bold  text-red-800">
                Feedback and Suggestions
              </h2>
              <p className="mt-2 text-gray-600">
                We value your feedback and are continuously working to improve
                Cayro. Your input is crucial in shaping the future of Cayro.
              </p>
            </div>

            <div>
              <h2 className="text-xl font-bold  text-red-800">
                Media Inquiries
              </h2>
              <p className="mt-2 text-gray-600">
                For media-related questions or press inquiries, please contact
                us at info@cayroshop.com.
              </p>
            </div>
          </div>
        </div>
      </main>

      {/* Contact Information Section */}
      <div className="flex flex-row lg:flex-col container mx-auto m-10 mb-20 lg:m-4 w-[80%] lg:w-[90%] p-5  lg:items-start">
        <div className="flex flex-wrap m-1">
          {/* Email Section */}
          <p className="mt-2 text-lg w-full lg:w-auto flex items-center text-gray-600">
            <img
              src={`${process.env.PUBLIC_URL}/mailIcon.svg`}
              alt="Email"
              className="w-5 h-5 rounded-lg mr-2"
            />
            info@cayroshop.com
          </p>

          {/* Phone Section */}
          <p className="mt-2 text-lg w-full lg:w-auto flex items-center text-gray-600">
            <img
              src={`${process.env.PUBLIC_URL}/callIcon.svg`}
              alt="Phone"
              className="w-5 h-5 rounded-lg mr-2"
            />
            9599383349
          </p>

          {/* Address Section */}
          <p className="mt-2 text-lg w-full lg:w-auto flex items-center text-gray-600">
            <img
              src={`${process.env.PUBLIC_URL}/addressIcon.svg`}
              alt="Address"
              className="w-5 h-5 rounded-lg mr-2"
            />
            Warehouse, At Kila No. 16, Mustil No. 20 V.P.O Kherki Daula, near
            Kherki Daula Toll Plaza, Gurugram, Haryana 122004
          </p>
        </div>

        <div className="w-[100%]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.512084316867!2d76.93374297549245!3d28.403801275790432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3da4ad9f6867%3A0xb22ebae52787a4eb!2sCayroShop!5e0!3m2!1sen!2sin!4v1726307977682!5m2!1sen!2sin"
            className="rounded-lg w-[95%] h-[130%]"
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default Contact;

// const Contact: React.FC = () => {
//   return (
//     <div className="px-8 sm:px-2  mt-[7rem] ">
//       <main className="container mx-auto py-12 md:py-0 sm:px-0 px-4 flex flex-row md:flex-col lg:justify-between items-start lg:items-center">
//         <div>
//           <h1 className="text-4xl sm:text-2xl font-bold text-gray-800">
//             Contact Us
//           </h1>
//           {/* <p className="mt-4 text-gray-600">
//             Email, call, or complete the form to learn how cayro can solve your
//             messaging problem.
//           </p> */}
//           <p className="mt-4 text-gray-600">
//             <strong>Email:</strong> info@cayroshop.com
//           </p>
//           <p className="mt-2 text-gray-600">
//             <strong>Phone:</strong> 9599383349
//           </p>
//           {/* <Link to="/" className="mt-2 text-blue-500">
//             Customer Support
//           </Link> */}

//           <div className="mt-8 space-y-8">
//             <div>
//               <h2 className="text-xl font-bold text-gray-800">
//                 Customer Support
//               </h2>
//               <p className="mt-2 text-gray-600">
//                 Our support team is available around the clock to address any
//                 concerns or queries you may have.
//               </p>
//             </div>
//             <div>
//               <h2 className="text-xl font-bold text-gray-800">
//                 Feedback and Suggestions
//               </h2>
//               <p className="mt-2 text-gray-600">
//                 We value your feedback and are continuously working to improve
//                 cayro. Your input is crucial in shaping the future of cayro.
//               </p>
//             </div>
//             <div>
//               <h2 className="text-xl font-bold text-gray-800">
//                 Media Inquiries
//               </h2>
//               <p className="mt-2 text-gray-600">
//                 For media-related questions or press inquiries, please contact
//                 us at info@cayroshop.com.
//               </p>
//             </div>
//           </div>
//         </div>
//         <iframe
//           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.512084316867!2d76.93374297549245!3d28.403801275790432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d3da4ad9f6867%3A0xb22ebae52787a4eb!2sCayroShop!5e0!3m2!1sen!2sin!4v1726307977682!5m2!1sen!2sin"
//           className="rounded-lg w-[500px] h-[500px] sm:w-[400px] sm:mt-[1.3rem] h-[250px]"
//           allowFullScreen={true}
//           loading="lazy"
//           referrerPolicy="no-referrer-when-downgrade"
//         ></iframe>
//         {/* <div className="w-1/3  md:w-full mx-auto bg-white p-6 md:p-5 rounded-lg shadow-md m-6 md:mx-8  sm:flex-col md:flex-col md:px-3 ">
//           <h1 className="text-4xl font-bold text-gray-800">Get in Touch</h1>
//           <p className="mt-2 text-gray-600">You can reach us anytime.</p>
//           <form className="mt-4">
//             <div className="flex flex-col md:flex-row md:space-x-4">
//               <input
//                 type="text"
//                 placeholder="Full Name"
//                 className="w-full p-3 mt-4 md:mt-0 border border-gray-300 rounded-full"
//               />
//               <input
//                 type="email"
//                 placeholder="Your email"
//                 className="w-full p-3 mt-4 md:mt-0 border border-gray-300 rounded-full"
//               />
//             </div>
//             <div className="flex mt-4 border border-gray-300 rounded-full">
//               <select className="w-1/6 p-2 rounded-l-full">
//                 <option value="+91">+91</option>
//               </select>
//               <input
//                 type="tel"
//                 placeholder="Phone number"
//                 className="w-5/6 p-3 rounded-r-full"
//               />
//             </div>
//             <textarea
//               placeholder="How can we help?"
//               className="w-full h-32 p-3 mt-4 border border-gray-300 rounded-lg"
//             ></textarea>
//             <button
//               type="submit"
//               className="w-full bg-blue-500 text-white p-3 mt-4 rounded-full"
//             >
//               Submit
//             </button>
//           </form>
//         </div> */}
//       </main>
//     </div>
//   );
// };

// export default Contact;
