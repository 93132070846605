import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showErrorToast, showSuccessToast } from "../utils/toast";
import { RootState } from "../store/store";
import { GetRequest, PostRequest } from "../api/Request";
import { createUserInAlignBook } from "../utils/utils";
import { getAllProductInCart } from "./CartSlice";

interface LoginPayload {
  password: string;
  number: string;
}

interface SignUpPayload {
  _id:string;
  username: string;
  password: string;
  number: string;
  otp: string;
  addresses: [];
}

export const login = createAsyncThunk(
  "user/login",
  async (
    payload: LoginPayload,
    thunkAPI: { rejectWithValue: (arg0: string) => any }
  ) => {
    const { number, password } = payload;
    try {
      const response = await PostRequest("/login", {
        password,
        number,
      });
      if (response.status === 200) {
        localStorage.setItem("userToken", response.data.accessToken);
      
        await showSuccessToast("Logged in successfully");
        return response.data.profile;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      await showErrorToast(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);



export const sendOTP = createAsyncThunk(
  "user/sendLoginOTP",
  async (phoneNumber: string, { rejectWithValue }) => {
    try {
      const response = await PostRequest("/sendLoginOTP", { number: phoneNumber });

      if (response.status === 200) {
        await showSuccessToast("OTP Sent successfully");
        return response.data.profile;
      } else {
        return rejectWithValue(response.data.message || "Failed to send OTP");
      }
    } catch (error: any) {
      await showErrorToast(error.response?.data?.message || "An error occurred.");
      return rejectWithValue(error.response?.data?.message);
    }
  }
)

export const loginUsingOTP = createAsyncThunk(
  "user/loginUsingOTP",
  async (
    payload: { number: string; otp: string; navigate: (path: string) => void },
    { rejectWithValue, dispatch }
  ) => {
    const { number, otp, navigate } = payload;

    try {
      const response = await PostRequest("/loginUsingOTP", { number, otp });

      if (response.status === 200) {
        // Store token in localStorage
        localStorage.setItem("userToken", response.data.accessToken);

        // Fetch cart products after login
        await dispatch(getAllProductInCart());

        // Show success toast
        await showSuccessToast("Logged In Successfully");

        // Navigate to another page (e.g., dashboard or home)
        navigate("/dashboard");

        // Return user profile for Redux store
        return response.data.profile;
      } else {
        // Reject with an error message if response is not successful
        return rejectWithValue(response.data.message);
      }
    } catch (error: any) {
      // Handle unexpected errors
      await showErrorToast(error.response?.data?.message || "Login failed");
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred."
      );
    }
  }
);


export const loginUsingWhatsApp = createAsyncThunk(
  "user/send-whatsapp-otp",
  async (
    payload: { number: string},
    { rejectWithValue }
  ) => {
    const { number } = payload;
    console.log(number)
    try {
      const response = await PostRequest("/send-whatsapp-otp", { number });

      if (response.status === 200) {
        // Store token in localStorage
        localStorage.setItem("userToken", response.data.accessToken);

        await showSuccessToast("Logged In Successfully");

        // Navigate on successful login
       

        return response.data.profile;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error: any) {
      await showErrorToast(error.response?.data?.message || "Login failed");
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred."
      );
    }
  }
);


export const asyncLoginWithTruecaller = createAsyncThunk(
  "user/loginWithTrueCaller",
  async (
    reqId: { reqId: string },
    { rejectWithValue }
  ) => {
    try {
      // console.log("Initiating login request with payload:", payload);

      // Make the API call
      const response=await GetRequest(`/get-login-token/${reqId.reqId}`)
      // Assume response has a status code and data
      if (response.status === 200) {
        console.log("Login successful:", response.data);
        localStorage.setItem("userToken", response.data.accessToken);
        await showSuccessToast("Logged In Successfully");
        window.location.href = "/";


        return response.data; // Fulfill the thunk with the returned data
      } else {
        console.error("Login failed with error message:", response.data?.message);
        return rejectWithValue(response.data?.message || "Login failed");
      }
    } catch (error: any) {
      console.error("Error occurred during login request:", error);
      return rejectWithValue(error?.message || "An unexpected error occurred");
    }
  }
);


export const signUp = createAsyncThunk(
  "user/signUp",
  async (
    payload: SignUpPayload,
    thunkAPI: { rejectWithValue: (arg0: string) => any }
  ) => {
    const { username, password, number, otp } = payload;
    try {
      const response = await PostRequest("register", {
        username,
        password,
        number,
        otp,
      });
      if (response.status === 200) {
        // await createUserInAlignBook(response.data._id, username, number);
        localStorage.setItem("userToken", response.data.accessToken);
        await showSuccessToast("Registered successfully");
        return response.data.profile;
      } else {
        return thunkAPI.rejectWithValue(response.data);
      }
    } catch (error: any) {
      await showErrorToast(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface UserState {
  address: any;
  user: {
    name: string;
    _id:string;
    number: string;
    gender: string;
    email: string;
    addresses: [];
  };
  isLoggedIn: boolean;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  user: {
    _id:"",
    name: "",
    number: "",
    gender: "",
    email: "",
    addresses: [],
  },
  isLoggedIn: false,
  loading: false,
  error: null,
  address: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = {
        _id:"",
        name: "",
        number: "",
        gender: "",
        email: "",
        addresses: [],
      };
      state.isLoggedIn = false;
      localStorage.removeItem("userToken");
      localStorage.removeItem('cartLength')
    },
    setUser: (
      state,
      action: PayloadAction<{
        _id:string;
        name: string;
        number: string;
        gender: string;
        email: string;
        userIsLoggedIn: boolean;
        addresses: [];
      }>
    ) => {
      state.user = action.payload;
      state.isLoggedIn = action.payload.userIsLoggedIn;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
       
        state.user = {
          _id:action.payload._id,
          name: action.payload.username,
          number: action.payload.number,
          gender: action.payload.gender ? action.payload.gender : "",
          email: action.payload.email ? action.payload.email : "",
          addresses:
            action.payload?.addresses?.length > 0
              ? action.payload.addresses
              : [],
        };
        state.isLoggedIn = true;
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload || "Failed to login";
        state.isLoggedIn = false;
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        // Optionally set user info after sign-up
        state.user = {
          _id:action.payload._id,
          name: action.payload.username, // Adjust based on your API response
          number: action.payload.number, // Adjust based on your API response
          gender: action.payload.gender ? action.payload.gender : "",
          email: action.payload.email ? action.payload.email : "",
          addresses:
            action.payload?.addresses?.length > 0
              ? action.payload.addresses
              : [],
        };
        state.isLoggedIn = true; // Automatically log in after sign-up if desired
      })
      .addCase(signUp.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload || "Failed to sign up";
      });
  },
});

export const { logout, setUser } = userSlice.actions;
export default userSlice.reducer;
export const IsLoggedIn = (state: RootState) => {
  return state.user.isLoggedIn;
};
export const IsLoading = (state: RootState) => state.user.loading;
export const Error = (state: RootState) => state.user.error;

export const UserInfo = (state: RootState) => {
  return state.user.user;
};
